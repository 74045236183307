import React from 'react';
import styled from 'styled-components';
import { PageHeader, PageTitle } from '../../../shared/Page';
import {
  Table, TableCell, TableRow,
} from '../../../shared/Table';
import { Centralized } from '../../../shared/layout';

const StyledSaveTheEnvironmentAndMakeMoney = styled(Centralized)`
  margin-bottom: 5rem;
`;

const SaveTheEnvironmentAndMakeMoney = () => (
  <StyledSaveTheEnvironmentAndMakeMoney>
    <PageHeader>
      <PageTitle>Save the environment and make money</PageTitle>
    </PageHeader>
    <Table>
      <tbody>
        <TableRow>
          <TableCell left>Parcel size</TableCell>
          <TableCell>0-40</TableCell>
          <TableCell>40-60</TableCell>
          <TableCell>60-80</TableCell>
          <TableCell>80-100</TableCell>
        </TableRow>
        <TableRow>
          <TableCell left>Weight limit</TableCell>
          <TableCell>3kg</TableCell>
          <TableCell>4kg</TableCell>
          <TableCell>4kg</TableCell>
          <TableCell>5kg</TableCell>
        </TableRow>
        <TableRow>
          <TableCell left>Earn</TableCell>
          <TableCell bold>$4.50</TableCell>
          <TableCell bold>$5.50</TableCell>
          <TableCell bold>$7.50</TableCell>
          <TableCell bold>$9.00</TableCell>
        </TableRow>
      </tbody>
    </Table>
  </StyledSaveTheEnvironmentAndMakeMoney>
);

export { SaveTheEnvironmentAndMakeMoney };