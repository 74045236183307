import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import {
  CommutersBanner, CommutersPageHeader, CommuterBenefits, SaveTheEnvironmentAndMakeMoney,
} from '../components/modules/CommutersPage';
import { DownloadCommutersApp } from '../components/modules/common/DownloadHandCarryApp';
import { Quote } from '../components/shared/Quote';

const StyledCommutersPage = styled(Layout)`
  text-align: center;
`;

const CommutersPage = () => (
  <StyledCommutersPage headerTransparent>
    <Page>
      <CommutersBanner />
      <CommutersPageHeader />
      <CommuterBenefits />
      <Quote>
        I love doing Hand Carry because I pick up a couple of parcels each day
        and there’s my lunch and coffee paid for. I only do it when it suits me.
        I can still work my usual day job that I adore.
      </Quote>
      <SaveTheEnvironmentAndMakeMoney />
      <DownloadCommutersApp />
    </Page>
  </StyledCommutersPage>
);

export default CommutersPage;
