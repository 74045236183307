import React from 'react';
import styled from 'styled-components';
import { Section } from '../../../shared/Section';
import { CommuterBenefitList } from './CommuterBenefitList';
import { SCREEN_SIZE } from '../../../../style';

const StyledCommuterBenefits = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 1rem;
  ${SCREEN_SIZE.LG} {
    padding-bottom: 2rem;
  }
`;

const CommuterBenefits = () => (
  <StyledCommuterBenefits>
    <CommuterBenefitList />
  </StyledCommuterBenefits>
);

export { CommuterBenefits };