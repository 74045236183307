import React from 'react';
import WalletImageSource from '../images/wallet.png';
import WorldImageSource from '../images/world.svg';
import HeartImageSource from '../images/heart.png';
import IphoneImageSource from '../images/iphone.svg';
import { TwoColumnList } from '../../../shared/TwoColumnList';

const items = [
  { image: WalletImageSource, title: 'Earn extra income at your leisure', description: 'Earn extra money when it suits you. You simply help out on your way to where ever you’re already headed. When, how, and how many you deliver is up to you.' },
  { image: WorldImageSource, title: 'Help us make a difference', description: 'Every parcel that is shipped through Hand Carry utilises  energy that would otherwise be wasted, as well as saving the energy required to pick up and deliver it traditionally.' },
  { image: HeartImageSource, title: 'Getting paid to be active', description: 'You can earn extra income for making small detours to your existing schedule. You choose how far you’re willing to go.' },
  { image: IphoneImageSource, title: 'All you need is your smartphone', description: 'Hand Carry is available on both Android and IOS from the Google Play store and the iTunes store respectively. ' },
];

const CommuterBenefitList = () => (
  <TwoColumnList
    items={items}
  />
);

export { CommuterBenefitList };