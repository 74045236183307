import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style';
import { Centralized } from '../layout';

const StyledQuote = styled(Centralized)`
  color: ${STYLES.COLOR_BOULDER_GRAY};
  text-align: center;
  margin: 3rem auto;
  max-width: 60rem;
  font-size: ${STYLES.FONT_SIZE_XLG};
  line-height: ${STYLES.LINE_HEIGHT_LG};
  
  ${SCREEN_SIZE.LG} {
    font-size: ${STYLES.FONT_SIZE_XXLG};
    line-height: ${STYLES.LINE_HEIGHT_XLG};
  }
`;

const Quote = ({
  children,
}) => (
  <StyledQuote>
    { `"${children}"` }
  </StyledQuote>
);

Quote.propTypes = {
  children: PropTypes.string.isRequired,
};

export { Quote }