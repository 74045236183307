import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import CommutersBannerIconSource from './images/commutersBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledCommutersBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const CommutersBanner = () => (
  <StyledCommutersBanner color={STYLES.PAGE_COMMUTERS_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={CommutersBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>Anyone can do it</BannerTitle>
        <BannerDescription>
          As long as you have a smartphone. Whether
          you’re a student, employee, retired or anything in
          between. You can help make a difference.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledCommutersBanner>
);

export { CommutersBanner };